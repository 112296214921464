<template>
  <div class="info-detail">
    <el-collapse v-model="activeName" accordion>
      <!-- 基础信息 -->
      <el-collapse-item name="1">
        <template slot="title">
          <div class="item-title">
            <span class="title-step">1</span>
            <span class="title-text">基础信息</span>
          </div>
        </template>
        <once :registerProperty="rowData.registerProperty" :dataList="detailData.basisInfo" />
      </el-collapse-item>
      <!-- 基地信息 -->
      <el-collapse-item name="2">
        <template slot="title">
          <div class="item-title">
            <span class="title-step">2</span>
            <span class="title-text">基地信息</span>
          </div>
        </template>
        <second :dataList="detailData.baseInfoList" />
      </el-collapse-item>
      <!-- 活动信息 -->
      <el-collapse-item name="3">
        <template slot="title">
          <div class="item-title">
            <span class="title-step">3</span>
            <span class="title-text">活动信息</span>
          </div>
        </template>
        <third :nanfanDirection="rowData.nanfanDirection" :achievementInfoList="detailData.achievementInfoList" :cropInfoList="detailData.cropInfoList" />
      </el-collapse-item>
      <!-- 投入信息 -->
      <el-collapse-item name="4">
        <template slot="title">
          <div class="item-title">
            <span class="title-step">4</span>
            <span class="title-text">投入信息</span>
          </div>
        </template>
        <fourth :dataList="detailData.investmentInfo" />
      </el-collapse-item>
    </el-collapse>
    <hr style="margin-top: 42px">
    <div v-if="detailType === 1" class="examine-wrap">
      <h3 class="examine-tit">审核状态</h3>
      <div class="examine-text">{{ rowData.registerStatus }}</div>
      <div v-show="rowData.registerStatus === '已驳回'">驳回原因: {{ detailData.rejectReason }}</div>
      <div class="footer-btn">
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </div>
    <div v-if="detailType === 2" class="examine-wrap">
      <h3 class="examine-tit">审核意见</h3>
      <div class="examine-content">
        <div class="examine-item">
          <el-radio-group v-model="detailList.registerStatus" @change="handleRadio">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
          <p v-if="optionTip" class="examine-tip">请选择审核状态</p>
        </div>
        <div class="examine-item">
          <el-input v-if="detailList.registerStatus === 3"
           @change="handleTextarea"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="在此输入驳回原因"
            v-model="detailList.rejectReason">
          </el-input>
          <p v-if="textareaTip" class="examine-tip">请输入驳回原因</p>
        </div>
      </div>
      <div class="footer-btn">
        <el-button type="primary" @click="handleSubmit">提交</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Once from './modal-step/once';
import Second from './modal-step/second';
import Third from './modal-step/third';
import Fourth from './modal-step/fourth';

export default {
  data() {
    return {
      activeName: '1',
      detailList: {
        id: '',
        registerStatus: '', // 审核状态: 2、审核通过 3、驳回
        rejectReason: '' // 驳回理由
      },
      optionTip: false,
      textareaTip: false,
      detailData: {}
    };
  },
  components: {
    Once,
    Second,
    Third,
    Fourth
  },
  props: {
    rowData: {
      type: Object,
      default: () => {}
    },
    detailType: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.getDetailData();
  },
  methods: {
    // 获取详情
    async getDetailData(id) {
      const data = await this.$fetchData.fetchGet({}, '/api/keeponrecord/backend/register/queryDetail/' + this.rowData.id);
      if (data.code === '200') {
        this.detailData = data.result;
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    /** radio选择 */
    handleRadio() {
      this.optionTip = false;
      this.textareaTip = false;
    },
    handleTextarea() {
      this.textareaTip = this.detailList.rejectReason ? false : true;
    },
    /** 提交 */
    handleSubmit() {
      if (!this.detailList.registerStatus) {
        this.optionTip = true;
      } else if (this.detailList.registerStatus === 3 && !this.detailList.rejectReason) {
        this.textareaTip = true;
      } else {
        this.detailList.id = this.rowData.id;
        this.$emit('submit', this.detailList);
      }
    },
    /** 关闭 */
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="less" scoped>
.info-detail{
  /deep/.el-collapse{
    border: none;
    .el-collapse-item__header{
      background-color: #f0f0f0;
      margin-bottom: 10px;
      &.is-active{
        background-color: #f0f0f0;
      }
    }
    .el-collapse-item__wrap{
      border: none;
    }
  }
  .item-title{
    .title-step{
      display: inline-block;
      height: 28px;
      line-height: 28px;
      background-color: #1890ff;
      width: 28px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      border-radius: 50%;
      margin: 0 10px;
    }
    .title-text{
      font-size: 16px;
      font-weight: bold;
    }
  }
  .examine-wrap{
    padding: 10px;
    text-align: left;
    .examine-tit{
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
    }
    .examine-text{
      margin-bottom: 10px;
    }
    .examine-content{
      .examine-item{
        margin-bottom: 20px;
        .examine-tip{
          color: red;
        }
      }
    }
    .footer-btn{
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
