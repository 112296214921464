<template>
  <div class="session-step">
    <div class="item-session">
      <h3 class="item-tit">科研投入</h3>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">科研生产投入</span>
          <span class="content-text">{{ dataList.scientific }}万元</span>
        </div>
      </div>
    </div>
    <div class="item-session">
      <h3 class="item-tit">财政投入</h3>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">基础设施投入</span>
          <span class="content-text">{{ dataList.facilities }}万元</span>
        </div>
        <div class="content-row">
          <span class="content-label">管理投入</span>
          <span class="content-text">{{ dataList.manage }}万元</span>
        </div>
        <div class="content-row">
          <span class="content-label">其他投入</span>
          <span class="content-text">{{ dataList.other }}万元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>
<style lang="less" scoped>
.session-step{
  .item-session{
    padding: 0 30px;
    text-align: left;
    .item-tit{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      margin: 1rem 0;
      padding-left: 8px;
      position: relative;
      &::before{
        content: '';
        height: 18px;
        width: 3px;
        background-color: #265AE0;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
    .session-content{
      .content-row{
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        .content-label{
          width: 220px;
          text-align: right;
          padding-right: 80px;
          box-sizing: border-box;
          color: #333;
        }
      }
    }
    .more-session{
      padding: 1px 25px 20px;
      background-color: #f0f0f0;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .item-sub-tit{
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
