<template>
  <div class="session-step">
    <div class="item-session" v-for="(item, index) in dataList" :key="index">
      <h3 class="item-tit">基地{{ index + 1 }}</h3>
      <h4 class="item-sub-tit">生产用地</h4>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">基地名称</span>
          <span class="content-text">{{ item.baseName }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">总面积</span>
          <span class="content-text">{{ item.baseArea }}亩</span>
        </div>
        <div class="content-row">
          <span class="content-label">基地地址</span>
          <span class="content-text">{{ item.provinceName + item.cityName + item.districtName + item.villageName + item.baseAddress }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">基地经纬度</span>
          <span class="content-text">经度：{{ item.lat }}，纬度：{{ item.lng }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">基地性质</span>
          <span class="content-text">{{ item.baseNature === 1 ? '固定' : (item.baseNature === 2 ? '临时' : '') }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">土地取得方式</span>
          <span class="content-text">{{ item.methodAcquisition === 1 ? '租赁': (item.methodAcquisition === 2 ? '购买' : '') }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">合同起止年限</span>
          <span class="content-text">{{ item.contractStartDate ? item.contractStartDate : '-' }} 至 {{ item.contractEndDate ? item.contractEndDate : '-' }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">甲方名称</span>
          <span class="content-text">{{ item.partyaName }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">乙方名称</span>
          <span class="content-text">{{ item.partybName }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">基地分布</span>
          <div class="content-text check-text">
            <span v-if="item.coreArea">核心区</span>
            <span v-if="item.distributionArea">保护区和其他区域</span>
          </div>
        </div>
        <div class="content-row" v-if="item.coreArea">
          <span class="content-label">核心区面积</span>
          <span class="content-text">{{ item.coreArea }}亩</span>
        </div>
        <div class="content-row" v-if="item.distributionArea">
          <span class="content-label">保护区和其他区域面积</span>
          <span class="content-text">{{ item.distributionArea }}亩</span>
        </div>
        <div class="content-row">
          <span class="content-label">土地类型</span>
          <div class="content-text check-text">
            <span v-if="item.dryFarmlandArea">旱田</span>
            <span v-if="item.paddyFieldArea">水田</span>
            <span v-if="item.otherLandArea">其他</span>
          </div>
        </div>
        <div class="content-row" v-if="item.dryFarmlandArea">
          <span class="content-label">旱田面积</span>
          <span class="content-text">{{ item.dryFarmlandArea }}亩</span>
        </div>
        <div class="content-row" v-if="item.paddyFieldArea">
          <span class="content-label">水田面积</span>
          <span class="content-text">{{ item.paddyFieldArea }}亩</span>
        </div>
        <div class="content-row" v-if="item.otherLandArea">
          <span class="content-label">其他面积</span>
          <span class="content-text">{{ item.otherLandArea }}亩</span>
        </div>
        <div class="content-row">
          <span class="content-label">基地照片</span>
          <div class="content-text">
            <div class="demo-image__preview" v-if="item.planeImg">
              <el-image 
                style="width: 100px; height: 100px"
                :src="item.planeImg" 
                :preview-src-list="item.planeImg">
              </el-image>
            </div>
          </div>
        </div>
        <div class="content-row">
          <span class="content-label">土地流转合同</span>
          <div class="content-text">
            <div class="demo-image__preview" v-if="item.landContractImg">
              <el-image 
                style="width: 100px; height: 100px"
                :src="item.landContractImg" 
                :preview-src-list="item.landContractImg">
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <h4 class="item-sub-tit">配套设施</h4>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">用地总面积</span>
          <span class="content-text">{{ item.landArea }}亩</span>
        </div>
        <div class="content-row">
          <span class="content-label">总建筑面积</span>
          <span class="content-text">{{ item.buildArea }}m2</span>
        </div>
        <div class="content-row">
          <span class="content-label">楼栋数量</span>
          <span class="content-text">{{ item.buildingNumber }}栋</span>
        </div>
        <div class="more-session" v-for="(child, number) in item.architectureInfoList" :key="number">
          <h3 class="item-tit">第{{ number + 1 }}栋建筑信息</h3>
          <div class="session-content" v-for="(child, number) in item.architectureInfoList" :key="number">
            <div class="content-row">
              <span class="content-label">建筑形式</span>
              <span class="content-text">{{ child.modality === 1 ? '板楼' : (child.modality === 2 ? '塔楼' : '') }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">层数</span>
              <span class="content-text">{{ child.layers }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">建筑面积</span>
              <span class="content-text">{{ child.area }}（m2）</span>
            </div>
            <div class="content-row">
              <span class="content-label">地上面积</span>
              <span class="content-text">{{ child.groundArea }}（m2）</span>
            </div>
            <div class="content-row">
              <span class="content-label">地下面积</span>
              <span class="content-text">{{ child.undergroundArea }}（m2）</span>
            </div>
            <div class="content-row">
              <span class="content-label">建设时间</span>
              <span class="content-text">{{ child.buildTime }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">建设/备案手续是否齐全</span>
              <span class="content-text">{{ child.isComplete ? '是' : '否' }}</span>
            </div>
            <div class="content-row" v-if="child.isComplete">
              <span class="content-label">备案时间</span>
              <span class="content-text">{{ child.recordTime }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">是否有产权</span>
              <span class="content-text">{{ child.isProperty ? '是' : '否' }}</span>
            </div>
            <div class="content-row" v-if="child.isProperty">
              <span class="content-label">产权名称</span>
              <span class="content-text">{{ child.propertyName }}</span>
            </div>
          </div>
        </div>
        <div class="content-row">
          <span class="content-label">其他配套设施总面积</span>
          <span class="content-text">{{ item.otherArea }}（m2）</span>
        </div>
        <div class="session-content">
          <div class="more-session" v-for="(child, number) in item.facilitiesInfoList" :key="number">
            <div class="content-row">
              <span class="content-label">配套设施类型</span>
              <div class="content-text">
                <span v-if="child.type === 1">围墙</span>
                <span v-else-if="child.type === 2">道路</span>
                <span v-else-if="child.type === 3">晒场</span>
                <span v-else-if="child.type === 4">大棚</span>
                <span v-else>其他</span>
              </div>
            </div>
            <div class="content-row">
              <span class="content-label">面积</span>
              <span class="content-text">{{ child.area }}（m2）</span>
            </div>
            <div class="content-row">
              <span class="content-label">建设时间</span>
              <span class="content-text">{{ child.buildTime }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">是否完成农用地附属设施备案</span>
              <span class="content-text">{{ child.isRecord ? '是' : '否' }}</span>
            </div>
            <div class="content-row" v-if="child.isRecord">
              <span class="content-label">备案时间</span>
              <span class="content-text">{{ child.recordTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <h4 class="item-sub-tit">生产设备信息</h4>
      <div class="session-content">
        <div class="more-session" v-for="(child, number) in item.equipmentInfoList" :key="number">
          <h3 class="item-tit">{{ child.type === 1 ? '实验设备' :  '农机设备' }}</h3>
          <h4 class="item-sub-tit">设备{{ number + 1 }}</h4>
          <div class="session-content">
            <div class="content-row">
              <span class="content-label">设备名称</span>
              <span class="content-text">{{ child.name }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">设备数量</span>
              <span class="content-text">{{ child.number }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
      required: true
    }
  }
};
</script>
<style lang="less" scoped>
.session-step{
  .item-session{
    padding: 0 30px;
    text-align: left;
    .item-tit{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      margin: 1rem 0;
      padding-left: 8px;
      position: relative;
      &::before{
        content: '';
        height: 18px;
        width: 3px;
        background-color: #265AE0;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
    .session-content{
      .content-row{
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        .content-label{
          width: 250px;
          text-align: right;
          padding-right: 60px;
          box-sizing: border-box;
          color: #333;
        }
        .check-text{
          span{
            position: relative;
            display: inline-block;
            padding-right: 20px;
          }
          span:not(:last-child)::after{
            content: '';
            margin-right: 8px;
            position: absolute;
            border-right: 2px solid #999;
            pointer-events: none;
            box-sizing: border-box;
            right: 0;
            top: 5px;
            bottom: 5px;
          }
        }
      }
    }
    .more-session{
      padding: 1px 25px 20px;
      background-color: #f0f0f0;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .item-sub-tit{
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
