<template>
  <div class="session-step">
    <div class="item-session" v-if="nanfanDirection === '科研育种'">
      <h3 class="item-tit">南繁科研</h3>
      <div class="more-session" v-for="(item, index) in cropInfoList" :key="index">
        <h3 class="item-tit">作物{{ index + 1 }}</h3>
        <div class="session-content">
          <div class="content-row">
            <span class="content-label">作物名称</span>
            <span class="content-text">{{ item.cropName }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">品种数</span>
            <span class="content-text">{{ item.varietiesNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">育种组合数</span>
            <span class="content-text">{{ item.breedingNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">项目</span>
            <div class="content-text">
              <span v-if="item.project === 1">加代</span>
              <span v-else-if="item.project === 2">扩繁</span>
              <span v-else-if="item.project === 3">制种</span>
              <span v-else-if="item.project === 4">鉴定</span>
              <span v-else-if="item.project === 5">资源保存</span>
              <span v-else-if="item.project === 6">其他</span>
            </div>
          </div>
          <div class="content-row">
            <span class="content-label">是否转基因</span>
            <span class="content-text">{{ item.isTransgene == true ? '是' : '否' }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">种植时间</span>
            <span class="content-text">{{ item.plantStartTime ? item.plantStartTime : '-' }} 至 {{ item.plantEndTime ? item.plantEndTime : '-' }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">种植面积</span>
            <span class="content-text">{{ item.plantArea }}亩</span>
          </div>
        </div>
      </div>
      <h3 class="item-tit">南繁成果<span class="item-tit-tip">（填写上一年度成果情况）</span></h3>
      <div class="more-session" v-for="(item, index) in achievementInfoList" :key="index">
        <h3 class="item-tit">成果{{ index + 1}}（选填）</h3>
        <div class="session-content">
          <div class="content-row">
            <span class="content-label">作物类型</span>
            <span class="content-text">{{ item.cropName }}</span>
          </div>
        </div>
        <div v-for="(child, number) in item.varietiesInfoList" :key="number">
          <h4 class="item-sub-tit">品种{{ number + 1}}</h4>
          <div class="session-content">
            <div class="content-row">
              <span class="content-label">品种名称</span>
              <span class="content-text">{{ child.name }}</span>
            </div>
            <div class="content-row">
              <span class="content-label">审定机构性质</span>
              <div class="content-text">
                <span v-if="child.nature === 1">国审</span>
                <span v-else-if="child.nature === 2">省审</span>
                <span v-else-if="child.nature === 3">备案</span>
              </div>
            </div>
            <div class="content-row">
              <span class="content-label">上一年度销售额</span>
              <span class="content-text">{{ child.salesVolume }}万元</span>
            </div>
            <div class="content-row">
              <span class="content-label">累计推广面积-海南</span>
              <span class="content-text">{{ child.hainanArea }}亩</span>
            </div>
            <div class="content-row">
              <span class="content-label">累计推广面积-全国</span>
              <span class="content-text">{{ child.countryArea }}亩</span>
            </div>
            <div class="content-row">
              <span class="content-label">其他推广情况</span>
              <span class="content-text">{{ child.otherSituation }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-session" v-else>
      <h3 class="item-tit">南繁制种</h3>
      <div class="more-session" v-for="(item, index) in cropInfoList" :key="index">
        <h3 class="item-tit">作物{{ index + 1 }}</h3>
        <div class="session-content">
          <div class="content-row">
            <span class="content-label">作物名称</span>
            <span class="content-text">{{ item.cropName }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">制种面积</span>
            <span class="content-text">{{ item.plantArea }}亩</span>
          </div>
          <div class="content-row">
            <span class="content-label">产量</span>
            <span class="content-text">{{ item.yield }}万千克</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    achievementInfoList: {
      type: Array,
      default: () => [],
      required: true
    },
    cropInfoList: {
      type: Array,
      default: () => [],
      required: true
    },
    nanfanDirection: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="less" scoped>
.session-step{
  .item-session{
    padding: 0 30px;
    text-align: left;
    .item-tit{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      margin: 1rem 0;
      padding-left: 8px;
      position: relative;
      &::before{
        content: '';
        height: 18px;
        width: 3px;
        background-color: #265AE0;
        position: absolute;
        top: 3px;
        left: 0;
      }
      .item-tit-tip{
        font-size: 12px;
        font-weight: normal;
        color: #999;
      }
    }
    .session-content{
      .content-row{
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        .content-label{
          width: 220px;
          text-align: right;
          padding-right: 80px;
          box-sizing: border-box;
          color: #333;
        }
      }
    }
    .more-session{
      padding: 1px 25px 20px;
      background-color: #f0f0f0;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .item-sub-tit{
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
