<template>
  <div class="session-step">
    <div class="item-session" v-if="registerProperty === '其他' || registerProperty === '个人'">
      <h3 class="item-tit">个人信息</h3>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">姓名</span>
          <span class="content-text">{{ dataList.name }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">身份证号</span>
          <span class="content-text">{{ dataList.identityCard }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">南繁地址</span>
          <span class="content-text">{{ dataList.nanfanProvinceName + dataList.nanfanCityName + dataList.nanfanDistrictName + dataList.nanfanAddress }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">联系方式</span>
          <span class="content-text">{{ dataList.contactNumber }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">邮箱</span>
          <span class="content-text">{{ dataList.email }}</span>
        </div>
      </div>
    </div>
    <div class="item-session" v-if="registerProperty === '科研单位' || registerProperty === '高等院校' || registerProperty === '企业'">
      <h3 class="item-tit">单位信息</h3>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">单位名称</span>
          <span class="content-text">{{ dataList.name }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">单位级别</span>
          <div class="content-text">
            <span v-if="dataList.level === 1">国家级</span>
            <span v-else-if="dataList.level === 2">省级</span>
            <span v-else-if="dataList.level === 3">地级市</span>
            <span v-else-if="dataList.level === 4">其他</span>
          </div>
        </div>
        <div class="content-row">
          <span class="content-label">注册地址</span>
          <span class="content-text">{{ dataList.registerProvinceName + dataList.registerCityName + dataList.registerDistrictName + dataList.unitAddress }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">信用代码</span>
          <span class="content-text">{{ dataList.societyCode }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">法人姓名</span>
          <span class="content-text">{{ dataList.legalPerson }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">法人身份证号</span>
          <span class="content-text">{{ dataList.legalIdentityCard }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">联系方式</span>
          <span class="content-text">{{ dataList.phoneNumber }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">邮箱</span>
          <span class="content-text">{{ dataList.email }}</span>
        </div>
      </div>
    </div>
    <div class="item-session" v-if="registerProperty === '科研单位' || registerProperty === '高等院校' || registerProperty === '企业'">
      <h3 class="item-tit">南繁负责人信息</h3>
      <div class="session-content">
        <div class="content-row">
          <span class="content-label">姓名</span>
          <span class="content-text">{{ dataList.contactName }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">身份证号</span>
          <span class="content-text">{{ dataList.identityCard }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">职务职称</span>
          <span class="content-text">{{ dataList.position }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">联系电话</span>
          <span class="content-text">{{ dataList.contactNumber }}</span>
        </div>
        <div class="content-row">
          <span class="content-label">南繁地址</span>
          <span class="content-text">{{ dataList.nanfanProvinceName + dataList.nanfanCityName + dataList.nanfanDistrictName + dataList.nanfanAddress }}</span>
        </div>
      </div>
    </div>
    <div class="item-session">
      <h3 class="item-tit">人员信息</h3>
      <div class="more-session">
        <h3 class="item-tit">人数统计（单位：个）</h3>
        <div class="session-content">
          <div class="content-row">
            <span class="content-label">管理人员数</span>
            <span class="content-text">{{ dataList.managePersonalNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">专业技术人员数</span>
            <span class="content-text">{{ dataList.skillPersonalNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">海南工人</span>
            <span class="content-text">{{ dataList.hainanWorkerNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">外省工人</span>
            <span class="content-text">{{ dataList.otherWorkerNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">工人总数</span>
            <span class="content-text">{{ dataList.workerNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">其他人员数</span>
            <span class="content-text">{{ dataList.otherPersonalNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">从事南繁活动总人数</span>
            <span class="content-text">{{ dataList.personalNumber }}</span>
          </div>
        </div>
      </div>
      <div class="more-session">
        <h3 class="item-tit">重要人员信息（选填）</h3>
        <div class="session-content" v-for="(item, index) in dataList.personnelInfoList" :key="index">
          <h4 class="item-sub-tit">人员信息{{ index + 1}}</h4>
          <div class="content-row">
            <span class="content-label">重要人员身份</span>
            <div class="content-text">
              <span v-if="item.identity === 1">院士</span>
              <span v-else-if="item.identity === 2">教授(研究员)</span>
              <span v-else-if="item.identity === 3">课题组负责人</span>
              <span v-else-if="item.identity === 4">基地负责人</span>
              <span v-else-if="item.identity === 5">其他重要人员</span>
            </div>
          </div>
          <div class="content-row">
            <span class="content-label">姓名</span>
            <span class="content-text">{{ item.name }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">联系方式</span>
            <span class="content-text">{{ item.phoneNumber }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">职务职称</span>
            <span class="content-text">{{ item.position }}</span>
          </div>
          <div class="content-row">
            <span class="content-label">南繁活动时间</span>
            <span class="content-text">{{ item.activityStartDate ? item.activityStartDate : '-' }} 至 {{ item.activityEndDate ? item.activityEndDate : '-' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
      required: true
    },
    registerProperty: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="less" scoped>
.session-step{
  .item-session{
    padding: 0 30px;
    text-align: left;
    .item-tit{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      margin: 1rem 0;
      padding-left: 8px;
      position: relative;
      &::before{
        content: '';
        height: 18px;
        width: 3px;
        background-color: #265AE0;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
    .session-content{
      .content-row{
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        .content-label{
          width: 220px;
          text-align: right;
          padding-right: 80px;
          box-sizing: border-box;
          color: #333;
        }
      }
    }
    .more-session{
      padding: 1px 25px 20px;
      background-color: #f0f0f0;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .item-sub-tit{
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
